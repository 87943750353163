<template>
  <div class="dealOrder">
    <div class="dealOrderTitle">
      <div class="myDealOrder">
        <p>我的成交</p>
      </div>
    </div>
    <el-table :data="tableData" border>
      <el-table-column prop="name" label="商品分类"></el-table-column>
      <el-table-column prop="price" label="价格"></el-table-column>
      <el-table-column prop="quantity" label="数量"></el-table-column>
      <el-table-column prop="meterageUnit" label="计量单位"></el-table-column>
      <el-table-column prop="bsFlagName" label="买卖方向"></el-table-column>
      <el-table-column prop="bankName" label="银行名称"></el-table-column>
      <el-table-column prop="bankNumber" label="银行卡号"></el-table-column>
      <el-table-column prop="createTimeFormat" label="创建时间"></el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button type="danger" size="small" @click="viewDetails(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="pagination">
      <el-col :span="24">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </el-col>
    </el-row>
    <el-dialog v-dialogDrag title="订单详情" :visible.sync="dialogVisible" width="670px">
      <div class="orderDetails">
        <el-row>
          <el-col :span="4">
            <span class="listTitle">商品分类：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.name }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">价格：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.price }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">数量：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.quantity }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">买卖方向：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.bsFlagName }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">银行名称：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.bankName }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">银行卡号：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.bankNumber }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">仓库：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.warehouse }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">计量单位：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.meterageUnit }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">区域：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.origin }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">创建时间：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.createTimeFormat }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">买方公司名称：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.buyEnterpriseName }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">卖方公司名称：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.sellEnterpriseName }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">对手方编码：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.rfcode }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">对手方名称：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.rfname }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">备注：</span>
          </el-col>
          <el-col :span="20">
            <span class="listContent">{{ orderDetails.note }}</span>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      current: 1,
      pageSize: 10,
      totalPage: null,
      orderDetails: {},
      dialogVisible: false
    };
  },
  mounted() {
    this.getDealDatas();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDealDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDealDatas();
    },
    getDealDatas() {
      protocolFwd.param_queryTradeNote.param.page = this.current - 1;
      protocolFwd.param_queryTradeNote.param.size = this.pageSize;
      http.postFront(protocolFwd.param_queryTradeNote).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          const datas = value.content;
          for (let i = 0; i < datas.length; i++) {
            if (datas[i].bsFlag == "B") {
              datas[i].bsFlagName = "买入";
            } else if (datas[i].bsFlag == "S") {
              datas[i].bsFlagName = "卖出";
            }
            if (datas[i].createTime) {
              datas[i].createTimeFormat = global.util.DateFormate(
                datas[i].createTime
              );
            } else {
              datas[i].createTimeFormat = "--";
            }
          }
          this.tableData = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    viewDetails(item) {
      this.orderDetails = item;
      this.dialogVisible = true;
    }
  }
};
</script>
<style scoped>
.dealOrder {
  padding: 0 15px;
}
.dealOrderTitle {
  overflow: hidden;
}
.dealOrder .myDealOrder {
  float: left;
  line-height: 40px;
}
.dealOrderTitle .myDealOrder p {
  font-size: 16px;
}
.orderDetails {
  line-height: 35px;
}
.orderDetails .listTitle {
  display: block;
  text-align: right;
}
.orderDetails .listContent {
  margin-left: 5px;
}
.dealOrder .pagination {
  margin-top: 15px;
}
</style>
